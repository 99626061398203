import Vue from 'vue'
import TurbolinksAdapter from 'vue-turbolinks';

import LinkNew from './partners//components/link-new.vue'
import LinkDetails from './partners/components/link-detailsjs'

document.addEventListener('turbolinks:load', () => {
  const path = location.pathname;
  var AppView;

  switch(true){
    case path == "/partners/links/new":
      AppView = LinkNew; // => /links/new
      break;
    case path.match(/^\/links\/(\d+)/) != null:
      AppView = LinkDetails; // => /link/:id
      break;
  }



  if(AppView){
    // AppView['mixins'] = [TurbolinksAdapter]; // required to handled turbolinks

    // new Vue(AppView);


    var appelement = document.getElementById(AppView.el.replace('#', '')); // don't mount DOM if element does not exist on page
    if (appelement != null){ 
      //  AppView['mixins'] = [TurbolinksAdapter]; // required to handled turbolinks
      new Vue(LinkNew);
    }
  }
  
});
