import api from '../api/resources';

export default {
  el: "#LinkDetails",
  data: function () {
    return {
        showDelete: false
    }
  },
  computed: {},
  methods: {

    toggleDelete(){
      this.showDelete = !this.showDelete;
    },

    onDelete(){
      const id = $('#LinkDetails').data('id');

      api.deleteLink(id).then(response => {
        toastr.success('Link deleted');
        window.location.href = "/";
      }, response => {
        toastr.error('Link not deleted. Try again.');
      });
    }
  }

}
